<template>
  <v-card v-if="locationsLoading">
    <v-card-text>
      <v-progress-linear
        indeterminate
        color="primary"
        class="my-3"
      />
    </v-card-text>
  </v-card>

  <v-card v-else>
    <v-card-title>
      Selecteer de locaties voor deze gebruiker
    </v-card-title>

    <v-col>
      <v-switch
        v-if="accessToAllLocations"
        v-model="allLocationsSelected"
        label="Ziet alle locaties"
        @click="toggleAllLocations"
      />
      <p v-if="!accessToAllLocations && allLocationsSelected">
        U heeft niet voldoende rechten om de locaties van deze gebruiker aan te passen
      </p>
    </v-col>

    <v-list
      v-if="!allLocationsSelected"
      dense
    >
      <v-list-item
        v-for="loadedLocation in loadedLocations"
        :key="loadedLocation._id"
        @click="toggleLocation(loadedLocation._id)"
      >
        <v-list-item-action>
          <v-icon :color="locationChecked(loadedLocation._id) ? 'success' : 'grey'">
            {{
              locationChecked(loadedLocation._id)
                ? $vuetify.icons.values.checkboxOn
                : $vuetify.icons.values.checkboxOff
            }}
          </v-icon>
        </v-list-item-action>

        <v-list-item-content>
          {{ loadedLocation.title }}
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-col
      v-if="locationError"
    >
      <v-alert
        border="bottom"
        colored-border
        type="warning"
        elevation="2"
        class="mb-0"
      >
        {{ locationError }}
      </v-alert>
    </v-col>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  name: 'WolkUserLocationsEditor',
  props: {
    userId: {
      type: String,
      required: true,
    },
    organizationId: {
      type: String,
      required: true,
    },
    userLocationAssignments: {
      type: Array,
      default: () => [],
    },
    userLocations: {
      type: Array,
      default: () => [],
    },
    isSelf: {
      type: Boolean,
    },
  },
  data() {
    return {
      locationsLoading: false,
      loadedLocations: [],
      loadLocationsCancelToken: null,
      allLocationsSelected: false,
      locationError: null,
    };
  },
  computed: {
    accessToAllLocations() {
      // wolk-admin has access to all locations for all organizations
      if (this.$store.getters['auth/isAdmin']) {
        return true;
      }

      // user has no locations assigned => user has organization access
      if (Array.isArray(this.userLocations) && this.userLocations.length === 0) {
        return true;
      }

      return false;
    },
  },
  watch: {
    organizationId(val) {
      console.log({ newOrganizationId: val });

      if (val) {
        this.loadLocations();
      }
    },
  },
  beforeMount() {
    this.loadLocations();
  },
  methods: {
    locationChecked(locationId) {
      return this
        .userLocationAssignments
        .filter((ula) => ula.references.locationId === locationId).length === 1;
    },
    toggleLocation(locationId) {
      this.locationError = null;
      if (this.locationChecked(locationId)) {
        if (this.userLocationAssignments.length === 1 && !this.allLocationsSelected) {
          this.locationError = 'Er dient minimaal 1 locatie toegevoegd te zijn.';
          return;
        }
        this.stopUserLocationAssignment(locationId);
        return;
      }

      this.createUserLocationAssignment(locationId);
    },
    loadLocations() {
      if (this.loadLocationsCancelToken) {
        console.log('Calling cancel token');
        this.loadLocationsCancelToken();
      }
      if (Array.isArray(this.userLocationAssignments)
        && this.userLocationAssignments.length === 0) {
        this.allLocationsSelected = true;
      }

      const url = `${config.VUE_APP_API_BASE_URL}/locations`;

      const params = {};
      if (this.$store.getters['auth/isAdmin']) {
        params.organizationId = this.organizationId;
      }
      if (this.isSelf) {
        params.isSelf = true;
      }
      this.locationsLoading = true;

      axios({
        method: 'get',
        url,
        params,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        cancelToken: new axios.CancelToken((c) => {
          this.loadLocationsCancelToken = c;
        }),
      })
        .then((response) => {
          if (response && response.data) {
            if (this.$store.getters['auth/isAdmin']
              || (this.$store.getters['auth/isAdminUser'] && this.userLocations.length === 0)
              || this.isSelf) {
              this.loadedLocations = response.data.locations;
            } else {
              this.loadedLocations = response.data.locations
                .filter((l) => this.userLocations.includes(l._id));
            }
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('loadLocations is cancelled');
          } else if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }

          console.log({ errorConfig: error.config });
        })
        .finally(() => {
          this.locationsLoading = false;
          this.loadLocationsCancelToken = null;
        });
    },
    createUserLocationAssignment(locationId) {
      const url = `${config.VUE_APP_API_BASE_URL}/user-location-assignments`;
      const headers = { Authorization: `Bearer ${this.$store.state.auth.jwt}` };

      const data = {
        userId: this.userId,
        locationId,
        organizationId: this.organizationId,
      };

      axios({
        url,
        method: 'post',
        headers,
        data,
      })
        .then(() => {
          this.$emit('updated-user-location-assignments');
        })
        .catch(() => {
          this.locationError = 'Er liep iets mis bij het opslaan van de locaties.';
        });
    },
    stopUserLocationAssignment(locationId) {
      const userLocationAssignment = this
        .userLocationAssignments
        .find((ula) => ula.references.locationId === locationId);

      if (!userLocationAssignment) {
        return;
      }

      const url = `${config.VUE_APP_API_BASE_URL}/user-location-assignments/${userLocationAssignment._id}`;
      const headers = { Authorization: `Bearer ${this.$store.state.auth.jwt}` };

      const data = {
        stopped: true,
      };

      axios({
        url,
        method: 'patch',
        headers,
        data,
      })
        .then(() => {
          this.$emit('updated-user-location-assignments');
        })
        .catch(() => {
          this.locationError = 'Er liep iets mis bij het opslaan van de locaties.';
        });
    },
    toggleAllLocations() {
      this.locationError = null;
      const url = `${config.VUE_APP_API_BASE_URL}/user-location-assignments/all-locations`;
      const headers = { Authorization: `Bearer ${this.$store.state.auth.jwt}` };

      const data = {
        userId: this.userId,
        organizationId: this.organizationId,
        setAllLocations: this.allLocationsSelected,
      };

      axios({
        url,
        method: 'patch',
        headers,
        data,
      })
        .then(() => {
          this.$emit('updated-user-location-assignments');
        })
        .catch(() => {
          this.locationError = 'Er liep iets mis bij het opslaan van de locaties.';
        });
    },
  },
};
</script>
