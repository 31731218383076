<template>
  <v-container v-if="!user._id">
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h2>User Loading</h2>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-else>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Wijzig gebruiker {{ user.firstName.value }} {{ user.lastName.value }}

            <v-spacer />
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.firstName.value"
                  :error-messages="user.firstName.errors"
                  autofocus
                  label="Voornaam"
                  @blur="validateFirstName"
                  @input="user.firstName.errors = []"
                />
              </v-col>

              <v-col>
                <v-text-field
                  v-model="user.lastName.value"
                  :error-messages="user.lastName.errors"
                  label="Achternaam"
                  @blur="validateLastName"
                  @input="user.lastName.errors = []"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.email.value"
                  :error-messages="user.email.errors"
                  label="E-mail"
                  @blur="validateEmail"
                  @input="user.email.errors = []"
                />
              </v-col>

              <v-col>
                <v-select
                  v-model="user.type.value"
                  :error-messages="user.type.errors"
                  :items="types"
                  label="Type"
                  @input="user.type.errors = []"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-switch
                  v-model="user.isAdmin.value"
                  :disabled="user.isViewOnly.value"
                  label="Is admin (kan ook gebruikers/locaties toevoegen)"
                />
                <v-switch
                  v-model="user.isViewOnly.value"
                  :disabled="user.isAdmin.value"
                  label="Is view-only (kan niks aanpassen)"
                />
              </v-col>
            </v-row>

            <v-row v-if="userOrganizationId">
              <v-col>
                <v-btn @click="changeLocationsDialogOpen = true">
                  Locaties aanpassen
                </v-btn>

                <v-dialog
                  v-model="changeLocationsDialogOpen"
                  width="600"
                >
                  <v-card>
                    <wolk-user-locations-editor
                      :user-id="user._id"
                      :organization-id="userOrganizationId"
                      :user-location-assignments="userLocationAssignments"
                      :user-locations.sync="userLocations"
                      :is-self.sync="isSelf"
                      @updated-user-location-assignments="loadAll"
                    />
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              :loading="loading"
              @click="validateAndUpdate()"
            >
              Aanpassen
            </v-btn>

            <v-btn
              color="grey darken-1"
              text
              @click="$router.push({ name: 'users' })"
            >
              Terug
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

import WolkUserLocationsEditor from './UserLocationsEditor.vue';

export default {
  components: {
    WolkUserLocationsEditor,
  },
  data() {
    return {
      allUserLocationSelected: false,
      loading: false,
      changeLocationsDialogOpen: false,
      userOrganizationId: null,
      userLocationAssignments: [],
      user: {
        _id: null,
        firstName: {
          value: '',
          errors: [],
        },
        lastName: {
          value: '',
          errors: [],
        },
        email: {
          value: '',
          errors: [],
        },
        type: {
          value: '',
          errors: [],
        },
        isAdmin: {
          value: false,
          errors: [],
        },
        isViewOnly: {
          value: false,
          errors: [],
        },
      },
      userLocations: [],
      isSelf: false,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Wolk dashboard',
          disabled: false,
          exact: true,
          to: { name: 'home' },
        },
        {
          text: 'Gebruikers',
          disabled: false,
          exact: true,
          to: { name: 'users' },
        },
        {
          text: `Gebruiker ${this.user.firstName.value} ${this.user.lastName.value}`,
          disabled: false,
          exact: true,
          to: { name: 'usersShow', params: { id: this.$route.params.id } },
        },
        {
          text: 'Wijzig',
          disabled: true,
          exact: true,
          to: { name: 'usersEdit' },
        },
      ];
    },
    types() {
      return this.$store.state.app.userTypes.map((type) => ({ text: type.title, value: type.key }));
    },
  },
  beforeMount() {
    this.getLoggedInUser();
    this.loadAll();
  },
  methods: {
    loadAll() {
      this.loading = true;

      this
        .loadUser()
        .then(() => this.loadUserLocationAssignments())
        .finally(() => {
          this.loading = false;
        });
    },
    loadUser() {
      this.loading = true;

      const url = `${config.VUE_APP_API_BASE_URL}/users/${this.$route.params.id}`;

      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      })
        .then((response) => {
          this.user.firstName.value = response.data.user.firstName;
          this.user.lastName.value = response.data.user.lastName;
          this.user.email.value = response.data.user.email;
          this.user.type.value = response.data.user.userType;
          this.user.isAdmin.value = response.data.user.isAdmin;
          this.user.isViewOnly.value = response.data.user.isViewOnly;
          this.user._id = response.data.user._id;
          if (this.user._id === this.$store.state.auth.id) {
            this.isSelf = true;
          }
          if (this.$store.getters['myAccount/isAdmin']) {
            this.userOrganizationId = response.data.user.metaData.organizationId;
          } else if (this.$store.getters['myAccount/isAdminUser']) {
            this.userOrganizationId = this.$store.state.myAccount.userMetaData.organizationId;
          }
        });
    },
    loadUserLocationAssignments() {
      const url = `${config.VUE_APP_API_BASE_URL}/user-location-assignments`;

      const params = {
        userId: this.user._id,
        stopped: false,
      };

      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
        params,
      })
        .then((response) => {
          this.userLocationAssignments = response.data.userLocationAssignments;
        });
    },
    validateFirstName() {
      this.user.firstName.value = this.user.firstName.value.trim();

      const newErrors = [];
      if (!this.user.firstName.value) {
        newErrors.push('Is verplicht');
      }

      if (this.user.firstName.value.length > 17) {
        newErrors.push('Mag maximaal 17 karakters lang zijn');
      }

      this.user.firstName.errors = newErrors;
    },
    validateLastName() {
      this.user.lastName.value = this.user.lastName.value.trim();

      const newErrors = [];
      if (!this.user.lastName.value) {
        newErrors.push('Is verplicht');
      }

      if (this.user.lastName.value.length > 17) {
        newErrors.push('Mag maximaal 17 karakters lang zijn');
      }

      this.user.lastName.errors = newErrors;
    },
    validateEmail() {
      this.user.email.value = this.user.email.value.trim();

      const newErrors = [];
      if (!this.user.email.value) {
        newErrors.push('Is verplicht');
      }
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!pattern.test(this.user.email.value)) {
        newErrors.push('Is not a valid email');
      }

      this.user.email.errors = newErrors;
    },
    validateType() {
      const newErrors = [];
      if (!this.user.type.value) {
        newErrors.push('Is verplicht');
      }

      if (!this.types.map((t) => t.value).includes(this.user.type.value)) {
        newErrors.push('Is geen mogelijk type');
      }

      this.user.type.errors = newErrors;
    },
    updateUser() {
      const url = `${config.VUE_APP_API_BASE_URL}/users/${this.$route.params.id}`;
      this.loading = true;

      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'patch',
        data: {
          firstName: this.user.firstName.value,
          lastName: this.user.lastName.value,
          email: this.user.email.value,
          type: this.user.type.value,
          isAdmin: this.user.isAdmin.value,
          isViewOnly: this.user.isViewOnly.value,
        },
      })
        .then((response) => {
          if (response.data && response.data.modifiedCount === 1) {
            this.$store.commit('app/snackbarText', {
              text: 'De gebruiker is aangepast',
              visible: true,
            });
          }
          this.loadAll();
        });
    },
    validateAndUpdate() {
      this.validateFirstName();
      this.validateLastName();
      this.validateEmail();
      this.validateType();

      if (this.user.firstName.errors.length > 0) return;
      if (this.user.lastName.errors.length > 0) return;
      if (this.user.email.errors.length > 0) return;
      if (this.user.type.errors.length > 0) return;

      this.updateUser();
    },
    getLoggedInUser() {
      if (this.$store.getters['auth/isAdmin']) {
        return;
      }
      const url = `${config.VUE_APP_API_BASE_URL}/users/${this.$store.state.auth.id}`;
      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      }).then((response) => {
        const { user } = response.data;
        if (user) {
          this.userLocations = user.metaData?.locationIds || [];
        }
      });
    },
  },
};
</script>
